import request from '@/plugins/axios'

// 分页
export function userRemark(page, limit, data) {
  return request({
    url: `/userRemark/${page}/${limit}`,
    method: 'post',
    data
  })
}

// 标记
export function remarkMessage(data) {
  return request({
    url: `/userRemark`,
    method: 'post',
    data
  })
}

// 取消标记
export function clearRemark(id) {
  return request({
    url: `/userRemark/${id}`,
    method: 'DELETE'
  })
}

// 屏蔽账号
export function userBlacklistApi(data) {
  return request({
    url: `/userBlacklist`,
    method: 'post',
    data
  })
}
