import request from '@/plugins/axios'

// 群查询
export function telegramJoinChannel(current, limit, data) {
  return request({
    url: `/telegramJoinChannel/${current}/${limit}`,
    method: 'post',
    data
  })
}
// 群添加
export function telegramAddChannel(data) {
  return request({
    url: `/telegramJoinChannel`,
    method: 'post',
    data
  })
}
// 群删除
export function telegramDelChannel(id) {
  return request({
    url: `/telegramJoinChannel/${id}`,
    method: 'delete'
  })
}

// 获取验证码
export function getCode(data) {
  return request({
    url: `/telegramAccount/getCode`,
    method: 'post',
    data
  })
}
//账号提交
export function addAccount(data) {
  return request({
    url: `/telegramAccount/getCode`,
    method: 'post',
    data
  })
}
// 账号列表页
export function telegramAccount(current, limit, data) {
  return request({
    url: `/telegramAccount/${current}/${limit}`,
    method: 'post',
    data
  })
}

// 群详情
export function groupDetail(channelId) {
  return request({
    url: `/telegramChannels/${channelId}`
  })
}
// 群成员
export function userList(current, limit, data) {
  return request({
    url: `/telegramChannelUsers/${current}/${limit}`,
    method: 'post',
    data
  })
}
// 群组内用户发言热度
export function userList2(channelId, type) {
  return request({
    url: `/telegramChannelUsers/hotUserList/${channelId}/${type}`
  })
}

// 用户详情
export function userDetailApi(id) {
  return request({
    url: `/telegramUsersSearch/${id}`,
    method: 'GET'
  })
}
//
export function userInGroup(id) {
  return request({
    url: `/telegramChannelUsers/channelList/${id}`,
    method: 'GET'
  })
}
//
export function userCityApi(page, limit, data) {
  return request({
    url: `/telegramUserCity/group/${page}/${limit}`,
    method: 'post',
    data
  })
}
// /
export function channelAdminListApi(channelId) {
  return request({
    url: `/telegramChannelUsers/channelAdminList/${channelId}`
  })
}
// 群成员更新
export function telegramUpdateTaskApi(data) {
  return request({
    url: `/telegramUpdateTask`,
    method: 'post',
    data
  })
}
// 群图片数量设置
export function exportImgApi(channelId, limit) {
  return request({
    url: `/telegramChannels/exportImg/${channelId}/${limit}`,
    method: 'GET'
  })
}
